<template>
  <div>
    <form class="mb-3" @submit.prevent="sendForgotPasswordEmail">
      <p class="title">忘記密碼</p>
      <!-- 電子信箱 -->
      <div class="form-floating mb-3">
        <input
          type="email"
          class="form-control"
          id="Email"
          placeholder="輸入電子信箱"
          v-model="email"
        />
        <label for="Email">輸入電子信箱</label>
      </div>
      <button class="form-btn" type="submit">發送</button>
    </form>
    <router-link
      class="d-block text-end"
      :to="identity === 'seller' ? '/sellerLogin' : '/customerLogin'"
      >返回登入頁面 >></router-link
    >
  </div>
</template>

<script>

export default {
  data() {
    return {
      email: '',
      identity: 'customer',
    }
  },
  created() {
    // 初始化
    this.initialization()
  },
  methods: {
    // 初始化
    initialization() {
      this.email = ''
      this.identity = window.localStorage.getItem('identity') ? window.localStorage.getItem('identity') : 'customer'
    },
    // 寄送 email
    sendForgotPasswordEmail() {
      const vm = this
      const sendForgotPasswordEmailApi = `${process.env.VUE_APP_API}/user/sendForgottenPasswordEmail?emailAddress=${this.email}`
      this.$methods.switchLoading('show')
      $.ajax({
        type: 'get',
        async: true,
        url: sendForgotPasswordEmailApi,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert('200', res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert('other', err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
  },
}
</script>